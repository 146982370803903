import Script from "next/script";

const UA_DATA_INJECTION = `
(function() {
  const ua = navigator.userAgent
  document.getElementsByTagName('html')[0].setAttribute("data-useragent", ua)

  const mobileAgents = /Android|iPhone|iPad|Opera Mini|IEMobile|WPDesktop/i
  const iOS = /iPhone|iPad/i
  const isAndroid = /Android/i

  function checkMobile(userAgent) {
    return Boolean(mobileAgents.test(ua));
  }
  function checkIOS(userAgent) {
    return Boolean(iOS.test(ua));
  }
  function checkAndroid(userAgent) {
    return Boolean(isAndroid.test(ua));
  }

  const isMobile = checkMobile(ua)
  const getDataFont = function() {
    const isIOS = checkIOS(ua)
    const isAndroid = checkAndroid(ua)

    if (isMobile) {
      if (isIOS) return 'ios'
      if (isAndroid) return 'android'
    }

    return 'web'
  };

  document.getElementsByTagName('html')[0].setAttribute('data-font', getDataFont())
  document.getElementsByTagName('html')[0].setAttribute('data-mobile-device', isMobile)
}());
`;

export const DefaultScript = () => (
  <Script id="ua-data-injection">{UA_DATA_INJECTION}</Script>
);
