import { useDidMount } from "@src/hooks/useDidMount";
import { type ReactNode, useState } from "react";
import { createPortal } from "react-dom";

type PortalProps = {
  children: ReactNode;
};

export const Portal = ({ children }: PortalProps) => {
  const [container, setContainer] = useState<Element | null>(null);

  useDidMount(() => {
    if (document) {
      setContainer(document.body);
    }
  });

  return container && createPortal(children, container);
};
