import { clientSideOnly } from "@src/utils/enviroment/clinetSideOnly";
import { noop } from "@src/utils/function/noop";

export type UpdateVisitedHistoryProps = {
  canGoBack: boolean;
  actionBarHeight: number;
};

// eslint-disable-next-line @eslint-react/hooks-extra/no-redundant-custom-hook
export const useInterfaceBetweenApp = () => {
  const initInterfaceBetween = () => {
    clientSideOnly(() => {
      if (!window?.interfaceBetweenSocarAppAndSocarWeb) {
        window.interfaceBetweenSocarAppAndSocarWeb = {
          onPause: noop,
          onResume: noop,
          updateVisitedHistory: noop
        };
      }
    });
  };

  const setOnPause = (onPause: () => void) => {
    clientSideOnly(() => {
      if (!window?.interfaceBetweenSocarAppAndSocarWeb) {
        initInterfaceBetween();
      }

      window.interfaceBetweenSocarAppAndSocarWeb.onPause = onPause;
    });
  };

  const setOnResume = (onResume: () => void) => {
    clientSideOnly(() => {
      if (!window?.interfaceBetweenSocarAppAndSocarWeb) {
        initInterfaceBetween();
      }

      window.interfaceBetweenSocarAppAndSocarWeb.onResume = onResume;
    });
  };

  const setUpdateVisitedHistory = (
    updateVisitedHistory: (updateResult?: UpdateVisitedHistoryProps) => void
  ) => {
    clientSideOnly(() => {
      if (!window?.interfaceBetweenSocarAppAndSocarWeb) {
        initInterfaceBetween();
      }

      window.interfaceBetweenSocarAppAndSocarWeb.updateVisitedHistory =
        updateVisitedHistory;
    });
  };

  return {
    setOnPause,
    setOnResume,
    setUpdateVisitedHistory,
    initInterfaceBetween
  };
};
