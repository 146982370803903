import type { ReactNode } from "react";

interface WhenProps {
  if: boolean;
  children: ReactNode;
}

export const When = ({ if: condition, children }: WhenProps) => {
  return condition ? <>{children}</> : null;
};
