import { useState } from "react";
import Draggable, { type DraggableData } from "react-draggable";
import { Portal } from "../Portal";

interface DraggableDevButtonProps {
  onClick: () => void;
}

export const DraggableDevButton = ({ onClick }: DraggableDevButtonProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 8, y: -8 });

  const handleOnDrag = (data: DraggableData) => {
    setIsDragging(true);
    setPosition({ x: data.x, y: data.y });
  };

  const handleOnStop = () => {
    setTimeout(() => {
      setIsDragging(false);
    }, 100);
  };

  const handleDevButtonClick = () => {
    if (isDragging) {
      return;
    }

    onClick();
  };

  return (
    <Portal>
      <Draggable
        position={{ x: position.x, y: position.y }}
        onDrag={(_, data) => handleOnDrag(data)}
        onStop={handleOnStop}
      >
        <div
          className="tw-flex tw-items-center tw-justify-center tw-absolute tw-left-0 tw-bottom-0 tw-w-56 tw-h-56
     tw-bg-grey060 tw-rounded-16 tw-z-50"
        >
          <button
            type="button"
            onClick={handleDevButtonClick}
            onTouchEnd={handleDevButtonClick}
            className=" tw-text-white tw-font-bold"
          >
            DEV
          </button>
        </div>
      </Draggable>
    </Portal>
  );
};
