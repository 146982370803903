import { Button, Icon } from "@socar-inc/frontend-design-system";
import axios from "axios";
import cookie from "js-cookie";
import { Portal } from "../Portal";
import { When } from "../When";

interface DevToolsHalfViewProps {
  isDevToolsOpen: boolean;
  onClose: () => void;
}

export const DevToolsHalfView = ({
  isDevToolsOpen,
  onClose
}: DevToolsHalfViewProps) => {
  const handleAccessTokenButtonClick = async () => {
    const email = prompt("쏘카 이메일을 입력해주세요.");

    const response = await axios.get(
      `https://rnd-test.socar.me/v1/Test/GetAccessToken/${email}`
    );

    if (response.data === "해당 회원이 존재하지 않습니다.") {
      alert("해당 회원이 존재하지 않습니다.");
    } else {
      cookie.set("auth_token", response.data);
      alert(`액세스 토큰: ${response.data} 쿠키 주입 성공!`);
    }
  };

  return (
    <Portal>
      <When if={isDevToolsOpen}>
        <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-z-50">
          <div
            className="tw-relative tw-max-h-[480px] tw-min-h-[360px]
      tw-rounded-t16 tw-bg-grey060 tw-overflow-auto tw-rounded-t-16"
          >
            <div className="tw-sticky tw-top-0 tw-left-0 tw-flex tw-justify-between tw-items-center tw-px-24 tw-py-20 tw-bg-grey060 tw-z-10">
              <div className="title1 tw-text-white">Elecle DevTools</div>
              <button type="button" onClick={onClose}>
                <Icon icon="ic24_close" color="white" />
              </button>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-16 tw-h-full tw-px-24 tw-py-20">
              <Button
                onClick={handleAccessTokenButtonClick}
                text="액세스 토큰 발급"
                type="regularFill"
                color="blue"
              />
            </div>
          </div>
        </div>
      </When>
    </Portal>
  );
};
